import React from 'react';
import InputMask from 'react-input-mask';

const onlyNumbers = (str) => str.replace(/[^0-9]/g, '');

const MaskedInput = ({onChange, mask, className, readOnly, onClick = null, placeholder = '', name = '', defaultValue = '' }) => {
  function handleChange(event) {
    onChange({
      ...event,
      target: {
        ...event.target,
        value: event.target.value
      }
    });
  }

  return (
    <InputMask
        className={className}
        mask={mask}
        value={defaultValue}
        placeholder={placeholder}
        onChange={handleChange}
        // defaultValue={defaultValue}
        readOnly={readOnly}
        type='text'
        name={name}
    />
  );
};

export default MaskedInput;