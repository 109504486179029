import {useState, useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';

import { selectLeaderHomeClients } from './../../../../redux/home/clients/selectors';
import axiosProxy from '../../../../axiosProxy';
import { fetchLeaderHomeClientsData } from '../../../../redux/home/clients/asyncActions';

const ConfirmationPayment = ({ popupBack, unDisplay, query }) => {
    const dispatch = useDispatch()
    const {leaderHomeCheckClient} = useSelector(selectLeaderHomeClients)
    const [error, setError] = useState('')

    const [checkedPaymentType, setCheckedPaymentType] = useState(0)
    const [loading, setLoading] = useState(false)

    const [inform, setInform] = useState({
        id: 0,
        payment: 0,
        uslovie1: false,
        uslovie2: false
    })

    let paymentType = [
        {
            id: 1,
            excessName: 'Списать переплату со следующего месяца',
            remainderName: 'Перенести остаток на следующий месяц'
        },
        {
            id: 2,
            excessName: 'Списать переплату с последнего месяца',
            remainderName: 'Перенести остаток на последний месяц'
        },
    ]

    useEffect(()=>{
        setInform({
            id: leaderHomeCheckClient.id,
            payment: leaderHomeCheckClient.monthlyPayment,
            uslovie1: false,
            uslovie2: false
        })
    }, [leaderHomeCheckClient.monthlyPayment])

    useEffect(()=>{
        setInform({
            id: leaderHomeCheckClient.id,
            payment: leaderHomeCheckClient.monthlyPayment,
            uslovie1: false,
            uslovie2: false
        })
        setCheckedPaymentType(0)
        setError('')

    }, [popupBack])

    const postData = async() =>{
        setError('')
        if(+inform.payment !== 0){            
            let requestData = {id: leaderHomeCheckClient.id, summ: inform.payment}
            if(+inform.payment !== leaderHomeCheckClient.monthlyPayment && +inform.payment !== leaderHomeCheckClient.remainderSumm){
                if(leaderHomeCheckClient.remainderPayment === 1){
                    setError('Последний платеж не может быть больше или меньше ожидаемой суммы*')
                }
                else if(checkedPaymentType !== 0){
                    requestData.lastOrNext = (checkedPaymentType === 1)? true: false;
                }
                else setError((inform.payment < leaderHomeCheckClient.monthlyPayment)? 'Введенная сумма меньше платежа, необходимо выбрать один из вариантов*': 'Введенная сумма больше платежа, необходимо выбрать один из вариантов*')
            }

            if((+inform.payment === leaderHomeCheckClient.monthlyPayment || +inform.payment === leaderHomeCheckClient.remainderSumm) || (checkedPaymentType !== 0 && leaderHomeCheckClient.remainderPayment !== 1)){
                setLoading(true)
                const { data } = await axiosProxy.post('/note/Client/payment-client', requestData,
                {
                    headers: {
                        "Accept": "application/json",
                        'Content-Type': 'application/json',
                        "Authorization": `Bearer ${(localStorage.getItem('save-autorize') === 'true')? JSON.parse(localStorage.getItem('AthorizationUserData')).token: JSON.parse(sessionStorage.getItem('AthorizationUserData')).token}` 
                    },
                });
                if(data){
                    dispatch(fetchLeaderHomeClientsData({count: 30, page: 0, query: query, isScrolling: false}))
                }
                setLoading(false)
                unDisplay()
            }
        }
        else{
            setError('Необходимо заполнить поле сумма*')
        }
    }

    const addPaymentTypeRadio = (item) =>{
        let {id, excessName, remainderName} = item;
        return(
            <div className='leader__popup-radio-container d-flex align-center' key={`leader__popup-radio-${id}`} onClick={()=>setCheckedPaymentType((id === checkedPaymentType)? 0: id)}>
                <div className={`leader__popup-radio-checkbox ${(id === checkedPaymentType)? 'active': ''}`}/>
                <div className='leader__popup-radio-label'>{(inform.payment < leaderHomeCheckClient.monthlyPayment)? remainderName: excessName}</div>
            </div>
        )
    }

	return (
        <div className="leader__popup-container pr-19 confirmation-payment">
            <div className='d-flex justify-between align-center mb-20'>
                <div className='leader__popup-title' onClick={()=>{
                    console.log(inform.payment, leaderHomeCheckClient.remainderSumm)
                }}>Платеж</div>
                <div className='popup__close-btn' onClick={unDisplay}/>
            </div>
            <div className='leader__popup-subtitle'>{leaderHomeCheckClient.clientName}</div>
            <label htmlFor="" className='leader__popup-label mt-20'>Сумма платежа</label>
            <input name='EnvelopeName' autoComplete="off" type="number" placeholder='' value={inform.payment} className="leader__popup-input" onChange={(e) => setInform({...inform, payment: e.target.value})}/>

            {
                (+inform.payment !== leaderHomeCheckClient.monthlyPayment && +inform.payment !== leaderHomeCheckClient.remainderSumm)?(
                    <>
                        <label htmlFor="" className='leader__popup-label mt-20'>{(inform.payment < leaderHomeCheckClient.monthlyPayment)? 'Введенная сумма меньше платежа, выберите один из вариантов': 'Введенная сумма больше платежа, выберите один из вариантов'}</label>
                        {
                            paymentType.map(addPaymentTypeRadio)
                        }
                    </>
                ): null
            }
            
            <div className='error-block fs-10 mt-10'>
                {error}
            </div>
            <div className='d-flex justify-between mt-10'>
                <div className='leader__popup-button delete fifty-button' onClick={unDisplay}>Отменить</div>
                <div className={`leader__popup-button fifty-button ${loading? 'none-click': ''}`} onClick={postData}>Подтвердить</div>
            </div>
        </div>
	)
}
export default ConfirmationPayment;
