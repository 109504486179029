import './index.scss'
import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchLeaderComplitedClientsData, fetchLeaderHomeClientsData } from '../../../../redux/home/clients/asyncActions';
import { selectLeaderHomeClients } from './../../../../redux/home/clients/selectors';
import {setLeaderHomeCheckClients} from '../../../../redux/home/clients/clientsSlice';
import { convertNumber, scrollingPagination } from '../../../../services/clientHome';
import ClientDetail from './../ClientDetail/ClientDetail';
import Galery from './../Galery/Galery';
import PaymentHistory from '../PaymentHistory/PaymentHistory';

const CompletedClients = ({unDisplay}) =>{
    const dispatch = useDispatch()

    const {leaderComplitedClientsData, completedPageLast} = useSelector(selectLeaderHomeClients)

    const [additionallyActiveId, setAdditionallyActiveId] = useState(0)
    const [page, setPage] = useState(0)
    const [popupDisplay, setPopupDisplay] = useState(false)
    const [popupType, setPopupType] = useState()
    const [query, setQuery] = useState('')
    const [scrollReset, setScrollReset] = useState(false)

    useEffect(()=>{
        dispatch(fetchLeaderComplitedClientsData({count: 30, page: 0, query: query, isSrolling: false}))
        setScrollReset(true)
        setTimeout(()=>{
            setScrollReset(false)
        }, 300)
    }, [query])

    const setPopupDisplayFunc = (item) =>{
        if(item){
            setPopupDisplay("display")
            setTimeout(()=>{
                setPopupDisplay("open")
            }, 300)
        }
        else{
            setPopupDisplay("display")
            setTimeout(()=>{
                setPopupDisplay("")
            }, 300)
        }
    }


    const addClientsTableItem = (item, i) =>{
        const {id, clientName, product, date, remainderSumm, remainderPayment} = item;
        return(
            <div className='leader__complited-clients-table-line-container' key={`leader__complited-clients-table-line-${id}-${date}-${clientName}`}>
                <div className="leader__complited-clients-table-line d-flex align-center justify-between">
                    <div className="leader__complited-clients-table-line-item number">
                        {i+1}
                    </div>
                    <div className="leader__complited-clients-table-line-item name">
                        {clientName}
                    </div>
                    <div className="leader__complited-clients-table-line-item product">
                        {product}
                    </div>
                    <div className="leader__complited-clients-table-line-item date">
                        {date}
                    </div>
                    <div className="leader__complited-clients-table-line-item remainder-summ">
                        {convertNumber(remainderSumm)} ₽
                    </div>
                    <div className="leader__complited-clients-table-line-item remainder-months">
                        {remainderPayment}
                    </div>
                    <div className="leader__complited-clients-table-line-button payment-history mr-5" onClick={()=>{
                        dispatch(setLeaderHomeCheckClients({ id, clientName}))
                        setPopupType('PaymentHistory')
                        setPopupDisplayFunc(true)
                    }}/>
                    <div className="leader__complited-clients-table-line-button detail" onClick={()=>{
                        dispatch(setLeaderHomeCheckClients({ id: id }))
                        setPopupType('ClientDetail')
                        setPopupDisplayFunc(true)
                    }}/>
                    <div className={`leader__complited-clients-table-line-button ml-5 open ${(additionallyActiveId === id)? 'active': ''}`} onClick={()=>setAdditionallyActiveId((additionallyActiveId === id)?0:id)}/>
                </div>
                {
                    (window.innerWidth <= 1200)?(
                        <div className={`leader__complited-clients-table-line-additionally ${(additionallyActiveId === id)? 'active': ''}`}>
                            <div className='leader__complited-clients-table-line-additionally-item product align-center'>
                                <div className='leader__complited-clients-table-line-additionally-item-label'>Товар:</div>
                                <div className='leader__complited-clients-table-line-additionally-item-title'>{product}</div>
                            </div>
                            <div className='leader__complited-clients-table-line-additionally-item date align-center'>
                                <div className='leader__complited-clients-table-line-additionally-item-label'>Дата последнего платежа:</div>
                                <div className='leader__complited-clients-table-line-additionally-item-title'>{date}</div>
                            </div>
                            <div className='leader__complited-clients-table-line-additionally-item remainder-summ align-center'>
                                <div className='leader__complited-clients-table-line-additionally-item-label'>Сумма:</div>
                                <div className='leader__complited-clients-table-line-additionally-item-title'>{convertNumber(remainderSumm)} ₽</div>
                            </div>
                            <div className='leader__complited-clients-table-line-additionally-item remainder-months align-center'>
                                <div className='leader__complited-clients-table-line-additionally-item-label'>Срок рассрочки:</div>
                                <div className='leader__complited-clients-table-line-additionally-item-title'>{remainderPayment}</div>
                            </div>
                        </div>
                    ): null
                }
            </div>
        )
    }
    
    return(
        <div className="leader__popup-container table-popup">
            <div className='d-flex justify-between align-center mb-20'>
                <div className='leader__popup-title'>Завершенные клиенты</div>
                <div className='popup__close-btn' onClick={unDisplay}/>
            </div>
            <div className="leader__complited-clients-table-container table-popup pf-0 bg-none">
                <div className='leader__complited-clients-table-search-container align-center'>
                    <div className='leader__complited-clients-table-search-icon'/>
                    <input type="text" placeholder='Поиск'  className='leader__complited-clients-table-search-input' value={query} onChange={(e)=>{setQuery(e.target.value)}}/>                 
                </div>
                <div className='leader__complited-clients-table-line-container title'>
                    <div className="leader__complited-clients-table-line d-flex align-center justify-between">
                        <div className="leader__complited-clients-table-line-item number">#</div>
                        <div className="leader__complited-clients-table-line-item name">ФИО</div>
                        <div className="leader__complited-clients-table-line-item product">Товар</div>
                        <div className="leader__complited-clients-table-line-item date">Дата последнего платежа</div>
                        <div className="leader__complited-clients-table-line-item remainder-summ">Сумма</div>                        
                        <div className="leader__complited-clients-table-line-item remainder-months">Мес. платеж</div>
                        <div className="leader__complited-clients-table-line-button"/>
                        <div className="leader__complited-clients-table-line-button ml-10"/>
                        <div className={`leader__complited-clients-table-line-button ml-10 open title`}/>
                    </div>
                </div>

                <div className="leader__complited-clients-table" onScroll={(e)=>scrollingPagination(e, ()=>setPage(page+1), ()=>dispatch(fetchLeaderHomeClientsData({count: 30, page: page+1, query: query, isSrolling: true})), completedPageLast, scrollReset)}>
                    {
                        leaderComplitedClientsData.map(addClientsTableItem)
                    }
                </div>
            </div>
            <Popup type={popupType} setPopupTypeFunction={setPopupType} display={popupDisplay} displayFunc={setPopupDisplayFunc}/>
        </div>
    )
}

const Popup = ({display, type, displayFunc, setPopupTypeFunction}) =>{
    const [popupType, setPopupType] = useState('')
    const ClosePopup = (event) =>{
        if(event.currentTarget === event.target && popupType !== 'Galery'){
            displayFunc(false)
            setPopupTypeFunction('')
        }
        if(event.currentTarget === event.target && popupType === 'Galery'){
            setPopupType('')
        }          
    }
    return(
        <div className={`popup__back catalog ${display}`}>
            <div className="popup__back-wrap d-flex justify-center align-center" onClick={(event)=> ClosePopup(event)}>
                {
                    (type === "ClientDetail" && popupType !== 'Galery')? <ClientDetail popupBack={display} unDisplay={()=>displayFunc(false)} popupTypeEdit={setPopupType} completed={true}/>
                    :(type === "PaymentHistory")? <PaymentHistory popupBack={display} unDisplay={()=>displayFunc(false)}/>
                    :(popupType === "Galery")? <Galery unDisplay={()=>setPopupType('')}/>
                    : null
                }
            </div>
        </div>
    )
}

export default CompletedClients;