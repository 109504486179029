import Header from "./Header/Header"
import Home from "./Home/Home";
import Popup from './Popup/Popup';
import { useState } from 'react';


const Admin = () =>{
    const [popupType, setPopupType] = useState()
    const [query, setQuery] = useState('')
    return(
        <div className="admin">
            <Header setPopupType={setPopupType} query={query} setQuery={setQuery}/>
            <div className="admin-wrapper">
                <Home setPopupType={setPopupType} query={query}/>
            </div>
            <Popup type={popupType}/>
        </div>
    )
}

export default Admin;