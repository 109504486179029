import { createAsyncThunk } from '@reduxjs/toolkit';
// import axios from 'axios';
import axiosProxy from '../../../axiosProxy';





export const fetchAdminHomeLeadersData = createAsyncThunk(
  'AdminHomeLeaders/fetchAdminHomeLeadersData',
  async () => {
    const { data:{adminHomeLedersData} } = await axiosProxy.get('note/Admin/get-user-list',
    {
        headers: {
            "Accept": "application/json",
            'Content-Type': 'application/json',
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Methods": "*",
            "Access-Control-Allow-Headers": "'Access-Control-Allow-Headers: Origin, Content-Type, X-Auth-Token'",
            "Authorization": `Bearer ${(localStorage.getItem('save-autorize') === 'true')? JSON.parse(localStorage.getItem('AthorizationUserData')).token: JSON.parse(sessionStorage.getItem('AthorizationUserData')).token}` 
        },
    }
    );
    console.log(adminHomeLedersData)
    return {list: adminHomeLedersData};
  },
);
