import Header from "./Header/Header"
import Home from "./Home/Home";
import { useState } from 'react';


const Leader = () =>{
    const [popupType, setPopupType] = useState()
    const [query, setQuery] = useState('')
    return(
        <div className="leader">
            <Header setPopupType={setPopupType} query={query} setQuery={setQuery}/>
            <div className="leader-wrapper">
                <Home setPopupType={setPopupType} popupType={popupType} query={query} setQuery={setQuery}/>
            </div>
        </div>
    )
}

export default Leader;