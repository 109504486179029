import { React, useState, useEffect } from 'react';
import Calendar from 'react-calendar';

// import { Calendar } from 'primereact/calendar';
        
import {useDispatch, useSelector} from 'react-redux';
import "./index.scss";

function CalendarRange({classes, range, setRange, }){
    return (
        <div className={`filters-full ${classes}`}>
            <div className='filters-full__scroll'>
                <div className={`filters-full__calendar-container`}>
                    <Calendar onChange={setRange} selectRange={true} value={(range !== null && typeof range === 'object' && range.length !== 0)? range: null}/>                  
                </div>
            </div>
        </div>
    )
}
export default CalendarRange;
