import { createSlice } from '@reduxjs/toolkit';
import { fetchLeaderClientPaymentHistoryData, fetchLeaderComplitedClientsData, fetchLeaderHomeCheckClient, fetchLeaderHomeClientsData, fetchLeaderConsolidatedReport } from './asyncActions';

const initialState = {
    leaderHomeClientsData: [],
    leaderComplitedClientsData: [],
    leaderHomeCheckClient: {
      id: 0,
      guid: '',
      clientName: '',
      clientPhone: '',   
      guarantorName: '',
      guarantorPhone: '',
      product: '', 
      installmentPeriod: 0,
      interestRate: 0,
      productPrice: 0,
      reamainderSumm: 0,
      address: '',
      dataFiles: [],
      anInitialFree: 0,
      monthlyPayment: 0,
      totalSumm: 0,
    },
    leaderHomeCheckClientDataFiles: {
      index: 0,
      files: []
    },
    leaderClientPaymentHistoryData: [],
    leaderConsolidatedReportData: {
      expenses: 0,
      income: 0,
      profit: 0
    },
    pageLast: false,
    scrollReset: false,
    completedPageLast: false,
};

const leaderHomeClients = createSlice({
  name: 'LeaderHomeClients',
  initialState,
  reducers: {
    setLeaderHomeClients(state, action) {
      state.leaderHomeClientsData = action.payload;      
    },
    setLeaderHomeClientsAdd(state, action) {
      state.leaderHomeClientsData = [...state.leaderHomeClientsData, action.payload];      
    },
    setLeaderHomeClientsEdit(state, action) {
      state.leaderHomeClientsData = state.leaderHomeClientsData.map(item=>(item.id === action.payload.id)? {...item, ...action.payload}: item);      
    },
    setLeaderHomeClientsDelete(state, action) {
      state.leaderHomeClientsData = state.leaderHomeClientsData.filter(item=>item.id!==action.payload);      
    },

    setLeaderHomeScrollReset(state, action) {
      state.scrollReset = action.payload;      
    },

    setLeaderComplitedClients(state, action) {
      state.leaderComplitedClientsData = action.payload;      
    },
    setLeaderComplitedClientsDataAdd(state, action) {
      state.leaderComplitedClientsData = [...state.leaderComplitedClientsData, action.payload];      
    },
    setLeaderComplitedClientsDataEdit(state, action) {
      state.leaderComplitedClientsData = state.leaderComplitedClientsData.map(item=>(item.id === action.payload.id)? {...item, ...action.payload}: item);      
    },
    setLeaderComplitedClientsDataDelete(state, action) {
      state.leaderComplitedClientsData = state.leaderComplitedClientsData.filter(item=>item.id!==action.payload);      
    },

    setLeaderClientPaymentHistoryData(state, action) {
      state.leaderClientPaymentHistoryData = action.payload;      
    },

    setLeaderHomeCheckClients(state, action) {
      state.leaderHomeCheckClient = {...state.leaderHomeCheckClient, ...action.payload};     
    },
    setLeaderHomeCheckClientDataFiles(state, action) {
      state.leaderHomeCheckClientDataFiles = action.payload;
    },
    setLeaderConsolidatedReportData(state, action) {
      state.leaderConsolidatedReportData = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(fetchLeaderHomeClientsData.pending, (state, action) => {
    });

    builder.addCase(fetchLeaderHomeClientsData.fulfilled, (state, action) => {
        if(action.payload.isScrolling){
          state.leaderHomeClientsData = [...state.leaderHomeClientsData, ...action.payload.list];
        }
        else
          state.leaderHomeClientsData = action.payload.list;

        if(action.payload.page === 0) state.scrollReset = true;



        if(action.payload.list.length < 30) state.pageLast = true;
        else state.pageLast = false;
    });

    builder.addCase(fetchLeaderHomeClientsData.rejected, (state, action) => {
    });


    builder.addCase(fetchLeaderComplitedClientsData.pending, (state, action) => {
    });

    builder.addCase(fetchLeaderComplitedClientsData.fulfilled, (state, action) => {
        if(action.payload.isScrolling){
          state.leaderComplitedClientsData = [...state.leaderComplitedClientsData, ...action.payload.list];
        }
        else
          state.leaderComplitedClientsData = action.payload.list;

        if(action.payload.page === 0) state.scrollReset = true;


        if(action.payload.list.length < 30) state.completedPageLast = true;
        else state.completedPageLast = false;
    });

    builder.addCase(fetchLeaderComplitedClientsData.rejected, (state, action) => {
    });


    builder.addCase(fetchLeaderClientPaymentHistoryData.pending, (state) => {
        state.leaderClientPaymentHistoryData = [];
    });
    builder.addCase(fetchLeaderClientPaymentHistoryData.fulfilled, (state, action) => {
        state.leaderClientPaymentHistoryData = action.payload.list;
    });



    builder.addCase(fetchLeaderHomeCheckClient.pending, (state, action) => {
      state.leaderHomeCheckClient = {
        id: state.leaderHomeCheckClient.id,
        guid: state.leaderHomeCheckClient.guid,
        clientName: '',
        clientPhone: '',   
        guarantorName: '',
        guarantorPhone: '',
        product: '', 
        installmentPeriod: 0,
        interestRate: 0,
        productPrice: 0,
        reamainderSumm: 0,
        address: '',
        dataFiles: [],
        anInitialFree: 0,
        monthlyPayment: 0,
        totalSumm: 0,
        comment: '',
        ...state.leaderHomeCheckClient.id,
      }
    });

    builder.addCase(fetchLeaderHomeCheckClient.fulfilled, (state, action) => {
        state.leaderHomeCheckClient = {guid: state.leaderHomeCheckClient.guid, ...action.payload.list};
    });

    builder.addCase(fetchLeaderHomeCheckClient.rejected, (state, action) => {
    });


    builder.addCase(fetchLeaderConsolidatedReport.pending, (state, action) => {
    });

    builder.addCase(fetchLeaderConsolidatedReport.fulfilled, (state, action) => {
        state.leaderConsolidatedReportData = action.payload.list;
    });

    builder.addCase(fetchLeaderConsolidatedReport.rejected, (state, action) => {
    });
  },
});

const { actions, reducer } = leaderHomeClients;


export default reducer;

export const { 
  setLeaderHomeClients,
  setLeaderHomeClientsAdd,
  setLeaderHomeClientsDelete,
  setLeaderComplitedClients,
  setLeaderComplitedClientsDataAdd,
  setLeaderComplitedClientsDataEdit,
  setLeaderComplitedClientsDatasDelete,
  setLeaderHomeCheckClients,
  setLeaderHomeClientsEdit,
  setLeaderHomeCheckClientDataFiles,
  setLeaderClientPaymentHistoryData,
  setLeaderConsolidatedReportData,
  setLeaderHomeScrollReset
} = actions;

