import './index.scss'
import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchLeaderClientPaymentHistoryData } from '../../../../redux/home/clients/asyncActions';
import { selectLeaderHomeClients } from './../../../../redux/home/clients/selectors';
import { convertNumber } from '../../../../services/clientHome';

const PaymentHistory = ({unDisplay, popupBack}) =>{
    const dispatch = useDispatch()

    const {leaderClientPaymentHistoryData, leaderHomeCheckClient} = useSelector(selectLeaderHomeClients)

    const [additionallyActive, setAdditionallyActive] = useState('')

    useEffect(()=>{
        if(leaderHomeCheckClient.id) dispatch(fetchLeaderClientPaymentHistoryData({id: leaderHomeCheckClient.id}))
    }, [leaderHomeCheckClient.id])

    const addHistoryTableItem = (item, i) =>{
        const {date, summ, factDate, factSumm} = item;
        return(
            <div className='leader__payment-history-table-line-container' key={`leader__payment-history-table-line-${date}`}>
                <div className="leader__payment-history-table-line d-flex align-center justify-between">
                    <div className="leader__payment-history-table-line-item number">
                        {i+1}
                    </div>
                    <div className="leader__payment-history-table-line-item date">
                        {date}
                    </div>
                    <div className="leader__payment-history-table-line-item summ">
                        {convertNumber(summ)} ₽
                    </div>
                    <div className="leader__payment-history-table-line-item fact-date">
                        {factDate? factDate: 'Отсутствует'}
                    </div>
                    <div className="leader__payment-history-table-line-item fact-summ">
                        {convertNumber(factSumm)} ₽
                    </div>
                    <div className={`leader__payment-history-table-line-button ml-10 open ${(additionallyActive === date)? 'active': ''}`} onClick={()=>setAdditionallyActive((additionallyActive === date)?0:date)}/>
                </div>
                {
                    (window.innerWidth <= 370)?(
                        <div className={`leader__payment-history-table-line-additionally ${(additionallyActive === date)? 'active': ''}`}>
                            <div className='leader__payment-history-table-line-additionally-item fact-date align-center'>
                                <div className='leader__payment-history-table-line-additionally-item-label'>Фактическая дата:</div>
                                <div className='leader__payment-history-table-line-additionally-item-title'>{factDate}</div>
                            </div>
                            <div className='leader__payment-history-table-line-additionally-item fact-summ align-center'>
                                <div className='leader__payment-history-table-line-additionally-item-label'>Фактическая сумма:</div>
                                <div className='leader__payment-history-table-line-additionally-item-title'>{convertNumber(factSumm)} ₽</div>
                            </div>
                        </div>
                    ): null
                }
            </div>
        )
    }
    
    return(
        <div className="leader__popup-container payment-history-table">
            <div className='d-flex justify-between align-center'>
                <div className='leader__popup-title '>История платежей</div>
                <div className='popup__close-btn' onClick={unDisplay}/>
            </div>
            <div className='leader__popup-payment-history-subtitle d-flex align-center'>
                <div className='leader__popup-payment-history-subtitle-icon'/>
                <div className='leader__popup-payment-history-subtitle-text'>{leaderHomeCheckClient.clientName}</div>
            </div>
            <div className="leader__payment-history-table-container table-popup pf-0 bg-none">
                <div className='leader__payment-history-table-line-container title'>
                    <div className="leader__payment-history-table-line d-flex align-center justify-between">
                        <div className="leader__payment-history-table-line-item number">№</div>
                        <div className="leader__payment-history-table-line-item date">Дата</div>
                        <div className="leader__payment-history-table-line-item summ">Сумма</div>
                        <div className="leader__payment-history-table-line-item fact-date">Факт. дата</div>
                        <div className="leader__payment-history-table-line-item fact-summ">Факт. сумма</div>                        
                        <div className={`leader__payment-history-table-line-button ml-10 open title`}/>
                    </div>
                </div>

                <div className="leader__payment-history-table">
                    {
                        leaderClientPaymentHistoryData.map(addHistoryTableItem)
                    }
                </div>
            </div>
        </div>
    )
}

export default PaymentHistory;