import {useState, useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
// import { postNotData } from '../../../../redux/actions/mineWarehouse';

import './index.scss';
import { SpecificRoundFunc, convertNumber } from '../../../../services/clientHome';

const Calculator = ({type, popupBack, unDisplay}) => {
    const dispatch = useDispatch()

    const [error, setError] = useState('')

    const [inform, setInform] = useState({
        id: 0,        
        price: 0,
        installmentPeriod: 0,
        interestRate: 0,
        anInitialFee: 0,
    })

    useEffect(()=>{
        setInform({
            id: 0,        
            price: 0,
            installmentPeriod: 0,
            interestRate: 0,
            anInitialFee: 0,
        })
    }, [popupBack])

    const postData = async(event) =>{
        event.preventDefault()
        unDisplay()
    }

	return (
        <div className="leader__popup-container pr-19">
            <div className='d-flex justify-between align-center mb-20'>
                <div className='leader__popup-title'>Калькулятор рассрочки</div>
                <div className='popup__close-btn' onClick={unDisplay}/>
            </div>
            <div className='leader__popup-container-scroll'>
                <RangeElem val={inform.price} lable={'Цена приобретения'} min={500} max={100000} step={500} onChangeFunc={(price)=>setInform({...inform, price: price})} lableClassName={'mt-0'}/>
                <RangeElem val={inform.installmentPeriod} lable={'Срок рассрочки'} min={1} max={24} step={1} onChangeFunc={(installmentPeriod)=>setInform({...inform, installmentPeriod: installmentPeriod})}/>
                <RangeElem val={inform.interestRate} lable={'Процентная ставка'} min={5} max={100} step={1} onChangeFunc={(interestRate)=>setInform({...inform, interestRate: interestRate})}/>
                <RangeElem val={inform.anInitialFee} lable={'Первоначальный взнос'} min={100} max={50000} step={100} onChangeFunc={(anInitialFee)=>setInform({...inform, anInitialFee: anInitialFee})}/>
            </div>
            <CalculatorBottom anInitialFee={inform.anInitialFee} monthlyPayment={(+inform.price + +inform.price/100*inform.interestRate - inform.anInitialFee) / ((inform.installmentPeriod > 0)? inform.installmentPeriod : 1)} totalSumm={+inform.price + +inform.price/100*+inform.interestRate}/>
        </div>
	)
}
export default Calculator;


export const RangeElem = ({val, lable, min, max, step, onChangeFunc, lableClassName=''}) =>{
    const [maxRange, setMaxRange] = useState(max)
    const editValFunc = (val) =>{
        let result = /^[0-9]*[.,]?[0-9]+$/.exec(val)
        if(result)onChangeFunc(+result[0])
        else if (val.length !== 1 && val[val.length-1] === '.' && val[val.length-2] !== '.' || val === '')onChangeFunc(val)
        if(maxRange < val){
            setMaxRange((maxRange * 2 > val)? maxRange * 2: val * 2)
        }
    }
    return(
        <>
            <label htmlFor="" className={`leader__popup-label mt-20 ${lableClassName}`}>{lable}</label>
            <input name='EnvelopeName' autoComplete="off" type="text" value={val} placeholder='' className="leader__popup-input" onChange={(e)=>editValFunc(e.target.value)}/>
            <input className='leader__popup-range mt-15' type="range" value={+val} step={step} min={min} max={maxRange} onChange={(e)=>onChangeFunc(e.target.value)}/>
        </>
    )
}

export const CalculatorBottom = ({anInitialFee, monthlyPayment, totalSumm, className=''}) =>{
    return(
        <div className={`leader__popup-calculator-bottom mt-20 d-flex justify-between ${className}`}>
            <div className='leader__popup-calculator-bottom-item'>
                <div className='leader__popup-calculator-bottom-item-title'>Взнос при покупке:</div>
                <div className='leader__popup-calculator-bottom-item-title mt-5'>{SpecificRoundFunc(anInitialFee)} ₽</div>
            </div>
            <div className='leader__popup-calculator-bottom-item green'>
                <div className='leader__popup-calculator-bottom-item-title'>Ежемесячный платеж:</div>
                <div className='leader__popup-calculator-bottom-item-title mt-5'>{SpecificRoundFunc(monthlyPayment)} ₽</div>
            </div>
            <div className='leader__popup-calculator-bottom-item red'>
                <div className='leader__popup-calculator-bottom-item-title'>Итоговая стоимость:</div>
                <div className='leader__popup-calculator-bottom-item-title mt-5'>{SpecificRoundFunc(totalSumm)} ₽</div>
            </div>
        </div>
    )
}