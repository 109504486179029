// import React from 'react';
import './index.scss';
import { LeaderPopupBackFunck } from '../../../redux/home/popup/popupSlice';
import { useDispatch } from 'react-redux';
import { setUserData } from '../../../redux/authorization/authorization';
import axiosProxy from './../../../axiosProxy';

const Header = ({setPopupType}) => {
    const dispatch = useDispatch()
    const logout = async () => {
        const response = await axiosProxy.post('note/Account/logout',
        {
            headers: {
                "Accept": "application/json",
                'Content-Type': 'application/json',
            },
        })
        if(response.status === 200)
            dispatch(setUserData({id: 1, name: '', token: '', role: ''}))

    }
 	return (
		<header className="admin__header">
            <div className='admin__header-container d-flex justify-between align-center'>
                <div className='d-flex align-center mr-30'>
                    <div className='admin__header-button-container'/>
                </div>
                <div className='admin__header-search-container align-center mr-30'>
                    <div className='admin__header-search-icon'/>
                    <input type="text" placeholder='Поиск' className='admin__header-search-input'/>                 
                </div>
                <div className='d-flex align-center'>
                    <div className='admin__header-loguot-container d-flex align-center' onClick={logout}>
                        <div className='admin__header-loguot-icon mr-5'/>
                        <div className='admin__header-loguot-title'>Выйти</div>
                    </div>
                </div>
            </div>
        </header>
	)
}
export default Header;