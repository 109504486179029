import Popup from '../Popup/Popup';
import './index.scss'
import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { LeaderPopupBackFunck } from './../../../redux/home/popup/popupSlice';
import { fetchLeaderHomeCheckClient, fetchLeaderHomeClientsData } from '../../../redux/home/clients/asyncActions';
import { selectLeaderHomeClients } from './../../../redux/home/clients/selectors';
import { setLeaderClientPaymentHistoryData, setLeaderHomeCheckClients, setLeaderHomeClients, setLeaderHomeClientsDelete, setLeaderHomeClientsEdit, setLeaderHomeScrollReset } from '../../../redux/home/clients/clientsSlice';
import axiosProxy from './../../../axiosProxy';
import { convertNumber, scrollingPagination } from '../../../services/clientHome';

const Home = ({setPopupType, popupType, query, setQuery}) =>{
    const dispatch = useDispatch()

    const {leaderHomeClientsData, pageLast, scrollReset} = useSelector(selectLeaderHomeClients)
    const [additionallyActiveId, setAdditionallyActiveId] = useState(0)
    const [page, setPage] = useState(0)
    // const [scrollReset, setScrollReset] = useState(false)

    useEffect(()=>{
        dispatch(fetchLeaderHomeClientsData({count: 30, page: 0, query: query, isScrolling: false}))
        // setScrollReset(true)
        // setTimeout(()=>{
        //     setScrollReset(false)
        // }, 300)
    }, [query])
    
    useEffect(()=>{
        setPage(0)
    }, [scrollReset])

    const addClientsTableItem = (item, i) =>{
        const {id, guid, clientName, product, monthlyPayment, date, deferment, remainderDays, remainderSumm, remainderPayment} = item;
        return(
            <div className={`leader__home-table-line-container`} key={`leader__home-table-line-${id}-${date}-${clientName}`}>
                <div className={`leader__home-table-line  ${(deferment)? 'deferment': ''} d-flex align-center justify-between`}>
                    <div className="leader__home-table-line-item number">
                        {i+1}
                    </div>
                    <div className="leader__home-table-line-item name">
                        {clientName}
                    </div>
                    <div className="leader__home-table-line-item product">
                        {product}
                    </div>
                    <div className="leader__home-table-line-item payment">
                        {convertNumber(monthlyPayment)} ₽
                    </div>
                    <div className="leader__home-table-line-item date">
                        {date}
                    </div>
                    <div className={`leader__home-table-line-item days ${(remainderDays < 4)? 'tc-red':(remainderDays < 11)? 'tc-yellow': ''}`}>
                        {
                            deferment? (
                                <>{remainderDays}<span>(О)</span></>
                            ): remainderDays
                        }
                    </div>
                    <div className="leader__home-table-line-item remainder-summ">
                        {convertNumber(remainderSumm)} ₽
                    </div>
                    <div className="leader__home-table-line-item remainder-months">
                        {remainderPayment}
                    </div>
                    <div className="leader__home-table-line-button payment mr-5" onClick={()=>{
                        dispatch(setLeaderHomeCheckClients({ id, monthlyPayment, remainderSumm, clientName, remainderPayment: remainderPayment}))
                        setPopupType('ClientPayment')
                        console.log(monthlyPayment)
                        LeaderPopupBackFunck(true, dispatch)
                    }}/>
                    <div className={`leader__home-table-line-button ${!deferment? 'deferment': 'deferment-on'} mr-5`} onClick={()=>{
                        if(!deferment){
                            dispatch(setLeaderHomeCheckClients({ id, date }))
                            setPopupType('ClientDefermentPayment')
                            LeaderPopupBackFunck(true, dispatch)
                        }
                        else{
                            dispatch(setLeaderHomeCheckClients({ id, clientName }))
                            setPopupType('ClientDefermentCancel')
                            LeaderPopupBackFunck(true, dispatch)
                        }
                    }}/>
                    <div className="leader__home-table-line-button payment-history mr-5" onClick={()=>{
                        dispatch(setLeaderHomeCheckClients({ id, clientName}))
                        // dispatch(setLeaderClientPaymentHistoryData([]))
                        setPopupType('PaymentHistory')
                        LeaderPopupBackFunck(true, dispatch)
                    }}/>
                    <div className="leader__home-table-line-button rollback-payment mr-5" onClick={()=>{
                        dispatch(setLeaderHomeCheckClients({ id, clientName}))
                        setPopupType('RollbackClientPayment')
                        LeaderPopupBackFunck(true, dispatch)
                    }}/>
                    <div className="leader__home-table-line-button detail" onClick={()=>{
                        dispatch(setLeaderHomeCheckClients({ id, guid }))
                        console.log(guid)
                        setPopupType('ClientEdit')
                        LeaderPopupBackFunck(true, dispatch)
                    }}/>
                    <div className={`leader__home-table-line-button ml-10 open ${(additionallyActiveId === id)? 'active': ''}`} onClick={()=>setAdditionallyActiveId((additionallyActiveId === id)?0:id)}/>
                </div>
                {
                    (window.innerWidth <= 900)?(
                        <div className={`leader__home-table-line-additionally ${(additionallyActiveId === id)? 'active': ''}`}>
                            <div className='leader__home-table-line-additionally-item product align-center'>
                                <div className='leader__home-table-line-additionally-item-label'>Товар:</div>
                                <div className='leader__home-table-line-additionally-item-title'>{product}</div>
                            </div>
                            <div className='leader__home-table-line-additionally-item payment align-center'>
                                <div className='leader__home-table-line-additionally-item-label'>Платеж:</div>
                                <div className='leader__home-table-line-additionally-item-title'>{convertNumber(monthlyPayment)} ₽</div>
                            </div>
                            <div className='leader__home-table-line-additionally-item date align-center'>
                                <div className='leader__home-table-line-additionally-item-label'>Дата:</div>
                                <div className='leader__home-table-line-additionally-item-title'>{date}</div>
                            </div>
                            <div className='leader__home-table-line-additionally-item days align-center'>
                                <div className='leader__home-table-line-additionally-item-label'>Дни:</div>
                                <div className='leader__home-table-line-additionally-item-title'>
                                    {
                                        deferment? (
                                            <>{remainderDays}<span>(О)</span></>
                                        ): remainderDays
                                    }
                                </div>
                            </div>
                            <div className='leader__home-table-line-additionally-item remainder-summ align-center'>
                                <div className='leader__home-table-line-additionally-item-label'>Остаток суммы:</div>
                                <div className='leader__home-table-line-additionally-item-title'>{convertNumber(remainderSumm)} ₽</div>
                            </div>
                            <div className='leader__home-table-line-additionally-item remainder-months align-center'>
                                <div className='leader__home-table-line-additionally-item-label'>Остаток платежей:</div>
                                <div className='leader__home-table-line-additionally-item-title'>{remainderPayment}</div>
                            </div>
                            <div className='d-flex align-center mb-10'>
                                <div className="leader__home-table-line-button payment additionally mr-5" onClick={()=>{
                                    dispatch(setLeaderHomeCheckClients({ id, monthlyPayment, remainderSumm, clientName, remainderPayment: remainderPayment}))
                                    setPopupType('ClientPayment')
                                    console.log(monthlyPayment)
                                    LeaderPopupBackFunck(true, dispatch)
                                }}/>
                                <div className={`leader__home-table-line-button additionally ${!deferment? 'deferment': 'deferment-on'} mr-5`} onClick={()=>{
                                    if(!deferment){
                                        dispatch(setLeaderHomeCheckClients({ id, date }))
                                        setPopupType('ClientDefermentPayment')
                                        LeaderPopupBackFunck(true, dispatch)
                                    }
                                    else{
                                        dispatch(setLeaderHomeCheckClients({ id, clientName }))
                                        setPopupType('ClientDefermentCancel')
                                        LeaderPopupBackFunck(true, dispatch)
                                    }
                                }}/>
                                <div className="leader__home-table-line-button payment-history additionally mr-5" onClick={()=>{
                                    dispatch(setLeaderHomeCheckClients({ id, clientName}))
                                    dispatch(setLeaderClientPaymentHistoryData([]))
                                    setPopupType('PaymentHistory')
                                    LeaderPopupBackFunck(true, dispatch)
                                }}/>
                                <div className="leader__home-table-line-button rollback-payment additionally mr-5" onClick={()=>{
                                    dispatch(setLeaderHomeCheckClients({ id, clientName}))
                                    setPopupType('RollbackClientPayment')
                                    LeaderPopupBackFunck(true, dispatch)
                                }}/>
                                <div className="leader__home-table-line-button additionally detail" onClick={()=>{
                                    dispatch(setLeaderHomeCheckClients({ id, guid }))
                                    setPopupType('ClientEdit')
                                    LeaderPopupBackFunck(true, dispatch)
                                }}/>
                            </div>
                        </div>
                    ): null
                }
            </div>
        )
    }
    
    return(
        <div className="leader__home">
            <div className="leader__home-top d-flex justify-between align-center">
                <div className="leader__home-top-title d-flex align-center">
                    Клиенты                     
                </div>

                <div className="leader__home-top-button-container d-flex align-center add-client" onClick={()=>{
                    setPopupType('AddClient')
                    LeaderPopupBackFunck(true, dispatch)
                }}>                  
                    <div className="leader__home-top-button-title">Добавить</div>
                    <div className="leader__home-top-button-icon"/>
                </div>
            </div>

            <div className="leader__home-table-container">
                <div className='leader__home-table-search-container align-center mr-30'>
                    <div className='leader__home-table-search-icon'/>
                    <input type="text" placeholder='Поиск' className='leader__home-table-search-input' value={query} onChange={(e)=>{setQuery(e.target.value)}}/>                 
                </div>
                <div className='leader__home-table-line-container title'>
                    <div className="leader__home-table-line title d-flex align-center justify-between">
                        <div className="leader__home-table-line-item number">#</div>
                        <div className="leader__home-table-line-item name">ФИО</div>
                        <div className="leader__home-table-line-item product">Товар</div>
                        <div className="leader__home-table-line-item payment">Платеж</div>
                        <div className="leader__home-table-line-item date">Дата</div>
                        <div className="leader__home-table-line-item days">Дни</div>
                        <div className="leader__home-table-line-item remainder-summ">Остаток суммы</div>
                        <div className="leader__home-table-line-item remainder-months">Остаток платежей</div>
                        <div className="leader__home-table-line-button mr-5"/>
                        <div className="leader__home-table-line-button mr-5"/>
                        <div className="leader__home-table-line-button mr-5"/>
                        <div className="leader__home-table-line-button mr-5"/>
                        <div className="leader__home-table-line-button"/>
                        <div className={`leader__home-table-line-button ml-10 open title`}/>
                    </div>
                </div>

                <div className="leader__home-table" onScroll={(e)=>scrollingPagination(e, ()=>setPage(page+1), ()=>dispatch(fetchLeaderHomeClientsData({count: 30, page: page+1, query: query, isScrolling: true})), pageLast, scrollReset, ()=>dispatch(setLeaderHomeScrollReset(false)))}>
                    {
                        leaderHomeClientsData.map(addClientsTableItem)
                    }
                </div>
            </div>
            <Popup type={popupType} props={{query: query}}/>
        </div>
    )
}

export default Home;