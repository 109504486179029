import {useDispatch, useSelector} from 'react-redux';

import { selectLeaderHomeClients } from './../../../../redux/home/clients/selectors';
import axiosProxy from '../../../../axiosProxy';
import { fetchLeaderHomeClientsData } from '../../../../redux/home/clients/asyncActions';

const ConfirmDeferment = ({ unDisplay, query }) => {
    const dispatch = useDispatch()
    const {leaderHomeCheckClient} = useSelector(selectLeaderHomeClients)

    const postData = async() =>{
        let requestData = {id: leaderHomeCheckClient.id}
        try {
            const { data } = await axiosProxy.post('/note/Client/remove-deferment', requestData,
            {
                headers: {
                    "Accept": "application/json",
                    'Content-Type': 'application/json',
                    "Authorization": `Bearer ${(localStorage.getItem('save-autorize') === 'true')? JSON.parse(localStorage.getItem('AthorizationUserData')).token: JSON.parse(sessionStorage.getItem('AthorizationUserData')).token}` 
                },
            });
            if(data){
                dispatch(fetchLeaderHomeClientsData({count: 30, page: 0, query: query, isScrolling: false}))
                unDisplay()
            }
        } catch (error) {
            
        }
    }

	return (
        <div className="leader__popup-container deferment-payment">
            <div className='d-flex justify-between align-center mb-20'>
                <div className='leader__popup-title calendar'>Отсрочка платежа</div>
                <div className='popup__close-btn' onClick={unDisplay}/>
            </div>
            <div className='leader__popup-confirmation-title'>Вы дествительно хотите отменить отсрочку клиента "{leaderHomeCheckClient.clientName}"?</div>
            <div className='filters-full__buttons d-flex justify-between mt-10'>
                <div className='leader__popup-button delete fifty-button' onClick={unDisplay}>Закрыть</div>
                <div className={`leader__popup-button fifty-button`} 
                onClick={postData}>Подтвердить</div>
            </div>
        </div>
	)
}
export default ConfirmDeferment;
