import React, {useState} from 'react';
import { createPortal } from 'react-dom';
import {useSelector, useDispatch} from 'react-redux';

import './index-1.0.scss';
import { selectLeaderPopup } from './../../../redux/home/popup/selectors';
import { LeaderPopupBackFunck } from '../../../redux/home/popup/popupSlice';
import Calculator from './Calculator/Calculator';
import AddClient from './AddClient/AddClient';
import ClientDetail from './ClientDetail/ClientDetail';
import ConfirmationPayment from './ConfirmationPayment/ConfirmationPayment';
import CompletedClients from './CompletedClients/CompletedClients';
import RollbackPayment from './RollbackPayment/RollbackPayment';
import Galery from './Galery/Galery';
import PaymentHistory from './PaymentHistory/PaymentHistory';
import { setLeaderHomeCheckClients } from '../../../redux/home/clients/clientsSlice';
import DefermentPayment from './DefermentPayment/DefetmentPayment';
import ConfirmDeferment from './ConfirmDeferment/ConfirmDeferment';
import ConsolidatedReport from './ConsolidatedReport/ConsolidatedReport';



const PopupContent = ({type, props}) => {
    const dispatch = useDispatch()
    const {popupBack} = useSelector(selectLeaderPopup)
    const [popupType, setPopupType] = useState('')

    const ClosePopup = (event) =>{
        if(event.currentTarget === event.target && popupType !== 'Galery'){            
            if(type === '') props.popupTypeEdit('')
            if(type !== "AddClient") LeaderPopupBackFunck(false, dispatch)
            dispatch(setLeaderHomeCheckClients({id: 0}))
        }
        if(event.currentTarget === event.target && popupType === 'Galery'){
            setPopupType('')
        }          
    }

	return (
        <div className={`popup__back ${popupBack}`}>
            <div className="popup__back-wrap d-flex justify-center align-center" onClick={(event)=> ClosePopup(event)}>
                {
                    (type === "Calculator")? <Calculator type={type} popupBack={popupBack} unDisplay={()=>LeaderPopupBackFunck(false, dispatch)}/>
                    :(type === "AddClient" && popupType !== "Galery")? <AddClient type={type} popupTypeEdit={setPopupType} query={props.query} popupBack={popupBack} unDisplay={()=>LeaderPopupBackFunck(false, dispatch)}/>
                    :(type === "ClientEdit" && popupType !== "Galery")? <ClientDetail query={props.query} popupBack={popupBack} unDisplay={()=>LeaderPopupBackFunck(false, dispatch)} popupTypeEdit={setPopupType}/>
                    :(type === "ClientPayment")? <ConfirmationPayment popupBack={popupBack} query={props.query} unDisplay={()=>LeaderPopupBackFunck(false, dispatch)}/>
                    :(type === "RollbackClientPayment")? <RollbackPayment query={props.query} unDisplay={()=>LeaderPopupBackFunck(false, dispatch)}/>
                    :(type === "CompletedClients")? <CompletedClients query={props.query} unDisplay={()=>LeaderPopupBackFunck(false, dispatch)}/>
                    :(type === "PaymentHistory")? <PaymentHistory popupBack={popupBack} unDisplay={()=>LeaderPopupBackFunck(false, dispatch)}/>
                    :(type === "ClientDefermentPayment")? <DefermentPayment query={props.query} popupBack={popupBack} unDisplay={()=>LeaderPopupBackFunck(false, dispatch)}/>
                    :(type === "ClientDefermentCancel")? <ConfirmDeferment query={props.query} unDisplay={()=>LeaderPopupBackFunck(false, dispatch)}/>
                    :(type === "ConsolidatedReport")? <ConsolidatedReport popupBack={popupBack} unDisplay={()=>LeaderPopupBackFunck(false, dispatch)}/>
                    :(popupType === "Galery")? <Galery unDisplay={()=>setPopupType('')}/>
                    : null
                }
            </div>
        </div>
	)
}

const Popup = ({type, props = null}) =>{
    return createPortal(<PopupContent type={type} props={props} />, document.body)
}
export default Popup;