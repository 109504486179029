import React, {useEffect} from 'react';
import Autorization from './components/Autorization/Autorization';
import Leader from './components/Leader/Leader';
import { selectAuthorizationData } from './redux/authorization/selectors';
import { useSelector } from 'react-redux';
import Admin from './components/Admin/Admin';

function App() {
  const {user} = useSelector(selectAuthorizationData)
  // console.log(user.role === 'leader', user.role)

  return (
    <React.Fragment>
      {
        (user.role === 'admin')? <Admin/>
        :(user.role === 'user')? <Leader/>
        :<Autorization/>
      }
    </React.Fragment>
  );
}

export default App;
