import { createSlice } from '@reduxjs/toolkit';

export const LeaderPopupBackFunck = (type, dispatch) => {
  if(type){
      // dispatch(setLeaderPopupBack('active'))
      // setTimeout(()=>{
      // dispatch(setLeaderPopupBack('open'))
      // }, 100)
      dispatch(setLeaderPopupBack('open'))
  }
  else{
      // dispatch(setLeaderPopupBack('active'))
      // setTimeout(()=>{
      // dispatch(setLeaderPopupBack(''))
      // }, 300)
      dispatch(setLeaderPopupBack(''))
  }

};

const initialState = {
    popupBack: '',
};

const leaderPopup = createSlice({
  name: 'leaderPopup',
  initialState,
  reducers: {
    setLeaderPopupBack(state, action) {
      state.popupBack = action.payload;      
    }
  },
});

const { actions, reducer } = leaderPopup;


export default reducer;

export const { 
    setLeaderPopupBack
} = actions;

