import { configureStore } from '@reduxjs/toolkit';
import { useDispatch } from 'react-redux';

import leaderPopup, { setLeaderPopupBack } from './home/popup/popupSlice';
import leaderHomeClients from './home/clients/clientsSlice';
import adminHomeLeaders from './admin/users/clientsSlice';
import authorization, { setUserData } from './authorization/authorization';



export const store = configureStore({
  reducer: {leaderPopup, leaderHomeClients, authorization, adminHomeLeaders},
  devTools: process.env.NODE_ENV !== 'production',
});

export const logout = () =>{
  store.dispatch(setUserData({id: 0, name: '', token: '', role: ''}))
  store.dispatch(setLeaderPopupBack({popupBack: ''}))
}

export default store;

export const useAppDispatch = () => useDispatch();

