import {useState, useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';

import { selectLeaderHomeClients } from './../../../../redux/home/clients/selectors';
import axiosProxy from '../../../../axiosProxy';
import { fetchLeaderHomeClientsData } from '../../../../redux/home/clients/asyncActions';
import Calendar from '../../../Calendar/Calendar';
import { getDateFormatFunc } from '../../../../services/clientHome';

const DefermentPayment = ({ popupBack, unDisplay, query }) => {
    const dispatch = useDispatch()
    const {leaderHomeCheckClient} = useSelector(selectLeaderHomeClients)
    const [error, setError] = useState('')

    const [loading, setLoading] = useState(false)

    const [inform, setInform] = useState({
        id: 0,
        date: '',
    })

    useEffect(()=>{
        setInform({
            id: leaderHomeCheckClient.id,
            date: '',
        })
        setError('')

    }, [popupBack])

    const postData = async() =>{
        let requestData = {id: leaderHomeCheckClient.id, date: new Intl.DateTimeFormat('en-CA', {year: 'numeric', month: '2-digit', day: '2-digit'}).format(getDateFormatFunc(inform.date.toLocaleDateString()))}
        try {
            const { data } = await axiosProxy.post('/note/Client/set-deferment', requestData,
                {
                    headers: {
                        "Accept": "application/json",
                        'Content-Type': 'application/json',
                        "Authorization": `Bearer ${(localStorage.getItem('save-autorize') === 'true')? JSON.parse(localStorage.getItem('AthorizationUserData')).token: JSON.parse(sessionStorage.getItem('AthorizationUserData')).token}` 
                    },
                });
                if(data){
                    dispatch(fetchLeaderHomeClientsData({count: 30, page: 0, query: query, isScrolling: false}))
                    unDisplay()
                }
        } catch (error) {
            console.log('Произошла какая-то ошибка')
        }
    }

	return (
        <div className="leader__popup-container deferment-payment">
            <div className='d-flex justify-between align-center mb-20'>
                <div className='leader__popup-title calendar'>Отсрочка платежа</div>
                <div className='popup__close-btn' onClick={unDisplay}/>
            </div>
            <Calendar date={leaderHomeCheckClient.date} checkDate={inform.date} setCheckDate={(date)=>setInform({...inform, date: date})}/>
            <div className='error-block'>{error}</div>
            <div className='filters-full__buttons d-flex justify-between mt-10'>
                <div className='leader__popup-button delete fifty-button' onClick={unDisplay}>Закрыть</div>
                <div className={`leader__popup-button fifty-button 
                ${(!loading && ((inform.date !== null && typeof inform.date === 'object') 
                || (inform.date.length === 10 && inform.date.indexOf('_') === -1)))? '': 'none-click'}`} 
                onClick={postData}>Подтвердить</div>
            </div>
        </div>
	)
}
export default DefermentPayment;
