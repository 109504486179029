// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.leader__popup-range {
  padding: 0;
  width: 100%;
  height: 16px;
  appearance: none;
  outline: none;
}
.leader__popup-range:focus {
  outline: none;
}
.leader__popup-range::-webkit-slider-thumb {
  -webkit-appearance: none;
  height: 16px;
  width: 16px;
  border-radius: 18px;
  background: #00d3ca;
  cursor: pointer;
  margin-top: -5px;
}
.leader__popup-range::-webkit-slider-runnable-track {
  height: 6px;
  cursor: pointer;
  background-color: rgba(0, 211, 200, 0.4039215686);
  border-radius: 5px;
}
.leader__popup-calculator-bottom.scroll-active {
  padding-right: 15px;
}
.leader__popup-calculator-bottom-item {
  font-size: 12px;
  font-weight: 400;
  width: 33%;
  color: #407fba;
}
.leader__popup-calculator-bottom-item.green {
  color: #8bb497;
}
.leader__popup-calculator-bottom-item.red {
  color: #f69595;
}
.leader__popup-calculator-bottom-item-title {
  text-align: center;
}`, "",{"version":3,"sources":["webpack://./src/components/Leader/Popup/Calculator/index.scss"],"names":[],"mappings":"AACI;EAEI,UAAA;EACA,WAAA;EACA,YAAA;EACA,gBAAA;EACA,aAAA;AADR;AAIQ;EACI,aAAA;AAFZ;AAOQ;EACI,wBAAA;EACA,YAAA;EACA,WAAA;EACA,mBAAA;EACA,mBAAA;EACA,eAAA;EACA,gBAAA;AALZ;AASQ;EACI,WAAA;EACA,eAAA;EAEA,iDAAA;EACA,kBAAA;AARZ;AAcQ;EACI,mBAAA;AAZZ;AAcQ;EACI,eAAA;EACA,gBAAA;EACA,UAAA;EACA,cAAA;AAZZ;AAcY;EACI,cAAA;AAZhB;AAeY;EACI,cAAA;AAbhB;AAgBY;EACI,kBAAA;AAdhB","sourcesContent":[".leader__popup{\n    &-range {\n        \n        padding: 0;\n        width: 100%;\n        height: 16px;\n        appearance: none;\n        outline: none;\n        \n\n        &:focus {\n            outline: none;\n            \n        }\n\n\n        &::-webkit-slider-thumb{\n            -webkit-appearance: none;\n            height: 16px;\n            width: 16px;\n            border-radius: 18px;\n            background: #00d3ca;            \n            cursor: pointer;\n            margin-top: -5px;\n\n        }\n        \n        &::-webkit-slider-runnable-track{\n            height: 6px;\n            cursor: pointer;\n            // background-color: #5eb1ff66;\n            background-color: #00d3c867;\n            border-radius: 5px;\n        }\n        \n    }\n\n    &-calculator-bottom{\n        &.scroll-active{\n            padding-right: 15px;\n        }\n        &-item{\n            font-size: 12px;\n            font-weight: 400;           \n            width: 33%;\n            color: #407fba;\n\n            &.green{\n                color: #8bb497;\n            }\n\n            &.red{\n                color: #f69595;\n            }\n            \n            &-title{\n                text-align: center;\n            }\n        }\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
