import { useState, useEffect } from 'react';
import { selectLeaderHomeClients } from './../../../../redux/home/clients/selectors';
import { useSelector } from 'react-redux';

const Galery = ({ unDisplay }) => {
    const {leaderHomeCheckClientDataFiles} = useSelector(selectLeaderHomeClients)
    const [imageIndex, setImageIndex] = useState(-1)

    useEffect(()=>{
        setImageIndex(leaderHomeCheckClientDataFiles.index)
    }, [leaderHomeCheckClientDataFiles])

	return (
        <div className="leader__popup-galery d-flex justify-center align-center">
            <div className='leader__popup-galery-close-btn' onClick={unDisplay}/>
            <div className={`leader__popup-galery-back-button ${(imageIndex === 0)? 'full-deactive-button': ''}`} onClick={()=>setImageIndex(imageIndex-1)}/>
            <img className='leader__popup-galery-image' src={(imageIndex !== -1)? leaderHomeCheckClientDataFiles.files[imageIndex]: ''} alt="" />
            <div className={`leader__popup-galery-next-button ${(imageIndex === leaderHomeCheckClientDataFiles.files.length-1)? 'full-deactive-button': ''}`} onClick={()=>setImageIndex(imageIndex+1)}/>
        </div>
	)
}
export default Galery;
