
import axiosProxy from './../axiosProxy';

export const scrollingPagination = (e, setPage, fetchData, pageLast, scrollReset=false, setScrollReset=()=>{}) =>{
    let scrollTop = e.target.offsetHeight + e.target.scrollTop;
    let height = e.target.scrollHeight;
    
    if( (+scrollTop.toFixed() === height || +scrollTop.toFixed() + 1 === height || +scrollTop.toFixed() -1 === height) && !pageLast &&!scrollReset){
        console.log(pageLast)
        fetchData()
        setPage()
    }

    if(scrollReset){
        console.log(1111111111111)
        e.target.scrollTo(0, 0);
        setScrollReset()
    }
}

export const DateFilterConvert = (range, month, year) =>{
    let monthKey = {
        "Январь": 1,
        "Февраль": 2,
        "Март": 3,
        "Апрель": 4,
        "Май": 5,
        "Июнь": 6,
        "Июль": 7,
        "Август": 8,
        "Сентябрь": 9,
        "Октябрь": 10,
        "Ноябрь": 11,
        "Декабрь": 12
    }
    let date = [];
    if(range.length === 0 && (month === 'Месяц' || month === null) && (year === 'Год' || year === null)){
        date = null;
    }
    else if(range.length === 0 && (month !== 'Месяц' && month !== null) && (year !== 'Год' && year !== null)){
        date[0] = new Date(year, monthKey[month]-1, 1);
        date[1] = new Date(year, monthKey[month], 1);
        date[1].setDate(date[1].getDate() - 1);
    }
    else if(range.length === 0 && (month === 'Месяц' || month === null) && (year !== 'Год' && year !== null)){
        date[0] = new Date(year, 0, 1);
        date[1] = new Date(year, 11, 31);
    }
    else if(range.length === 2){
        date = range;
        if(date[0].toLocaleDateString() === date[1].toLocaleDateString())
            date[1].setDate(date[1].getDate() + 1);
    }
    
    return date? {from: new Intl.DateTimeFormat('en-US', {year: 'numeric', month: '2-digit', day: '2-digit'}).format(date[0]).replaceAll('/', '.'), to: new Intl.DateTimeFormat('en-US', {year: 'numeric', month: '2-digit', day: '2-digit'}).format(date[1]).replaceAll('/', '.')}: null
}

export const convertNumber = (number) =>{
    // return new Intl.NumberFormat("ru-RU").format(Math.round(number / 10) * 10);
    return new Intl.NumberFormat("ru-RU").format(number);
}


export const getDatePeriodFormatFunc = (date) =>{
    let range = date.split(' - ');
    let rangeStart = range[0].split('.');
    let rangeEnd = range[1].split('.');
    
    range[0] = new Date(`${+rangeStart[1]}/${+rangeStart[0]}/${+rangeStart[2]}`);
    range[1] = new Date(`${+rangeEnd[1]}/${+rangeEnd[0]}/${+rangeEnd[2]}`);
    return range;
}

export const getDateFormatFunc = (date) =>{
    let result = date.split('.');
    result = new Date(`${+result[1]}/${+result[0]}/${+result[2]}`);
    return result;
}

export const SpecificRoundFunc = (data) =>{
    let number = Math.floor(data)
    if(`${number}`.length < 4){
        return new Intl.NumberFormat("ru-RU").format(number)
    }
    else{
        let result = `${number}`;
        result = result.slice(result.length - 2)
        if(+result > 75)
            result = number - +result + 100;
        else if(+result > 25) 
            result = number - +result + 50;
        else
            result = number - +result;
        return new Intl.NumberFormat("ru-RU").format(result)
    }
}

export const CreateUser = async(data, dataFiles) =>{
    
}

export const choiceUserFilesFunc = (e, setFile, setUrl) =>{
    let file = e.target.files[0];
    let reader = new FileReader()
    if(file){
        reader.readAsDataURL(file);
        reader.addEventListener('load', (event) => {
            setUrl(event.target.result)
        });
        setFile(file)
    }
    
}

export const deleteUserFilesFunc = (number, src, setFiles, files, setUrl, urls) =>{
    setFiles(files.filter((item, key)=>number !== key))
    setUrl(urls.filter((item)=>item !== src))
}

export const deleteUserFilesPost = async(guid, link, setInformDatafiles, dataFiles) =>{
    const { data } = await axiosProxy.post('/note/Client/delete-files', {guid: guid, files: [link]},
    {
        headers: {
            "Accept": "application/json",
            'Content-Type': 'application/json',
            "Authorization": `Bearer ${(localStorage.getItem('save-autorize') === 'true')? JSON.parse(localStorage.getItem('AthorizationUserData')).token: JSON.parse(sessionStorage.getItem('AthorizationUserData')).token}` 
        },
    });

    if(data){
        // setInformDatafiles(dataFiles)
        setInformDatafiles(dataFiles.filter(item => item !== link))
    }
}

export const getThisMonthPeriod = () =>{
    let range = [new Date(), new Date()];
    range[0].setDate(1)
    range[1].setDate(1)
    range[1].setMonth(range[1].getMonth() + 1)
    range[1].setDate(range[1].getDate() - 1)
    
    console.log(range);
    
    return range;
}