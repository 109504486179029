import { createAsyncThunk } from '@reduxjs/toolkit';
// import axios from 'axios';
import axiosProxy from '../../../axiosProxy';
import { getDateFormatFunc } from '../../../services/clientHome';





export const fetchLeaderHomeClientsData = createAsyncThunk(
  'LeaderHomeClients/fetchLeaderHomeClientsData',
  async (dataObj={count:30, query:false, page:0, isScrolling: false}) => {
    let postData = {count: dataObj.count, page: dataObj.page}
    if(dataObj.query) postData.query = dataObj.query

    const { data:{clientsTbaleData} } = await axiosProxy.get(`note/Client/get-client-list?`+ new URLSearchParams(postData),
    {
        headers: {
            "Accept": "application/json",
            'Content-Type': 'application/json',
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Methods": "*",
            "Access-Control-Allow-Headers": "'Access-Control-Allow-Headers: Origin, Content-Type, X-Auth-Token'",
            "Authorization": `Bearer ${(localStorage.getItem('save-autorize') === 'true')? JSON.parse(localStorage.getItem('AthorizationUserData')).token: JSON.parse(sessionStorage.getItem('AthorizationUserData')).token}` 
        },
    }
    );
    return {list: clientsTbaleData, isScrolling: dataObj.isScrolling, page: dataObj.page};
  },
);

export const fetchLeaderComplitedClientsData = createAsyncThunk(
  'LeaderHomeClients/fetchLeaderComplitedClientsData',
  async (dataObj={count:30, query:false, page:0, isScrolling: false}) => {
    let postData = {count: dataObj.count, page: dataObj.page}
    if(dataObj.query) postData.query = dataObj.query

    const { data } = await axiosProxy.get(`/note/Client/get-finish-client-list?`+ new URLSearchParams(postData),
    {
        headers: {
            "Accept": "application/json",
            'Content-Type': 'application/json',
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Methods": "*",
            "Access-Control-Allow-Headers": "'Access-Control-Allow-Headers: Origin, Content-Type, X-Auth-Token'",
            "Authorization": `Bearer ${(localStorage.getItem('save-autorize') === 'true')? JSON.parse(localStorage.getItem('AthorizationUserData')).token: JSON.parse(sessionStorage.getItem('AthorizationUserData')).token}` 
        },
    }
    );
    return {list: data.data, isScrolling: dataObj.isScrolling, page: dataObj.page};
  },
);

export const fetchLeaderClientPaymentHistoryData = createAsyncThunk(
  'LeaderHomeClients/fetchLeaderClientPaymentHistoryData',
  async (dataObj) => {

    const { data } = await axiosProxy.get(`/note/Client/get-client-payments?`+ new URLSearchParams(dataObj),
    {
        headers: {
            "Accept": "application/json",
            'Content-Type': 'application/json',
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Methods": "*",
            "Access-Control-Allow-Headers": "'Access-Control-Allow-Headers: Origin, Content-Type, X-Auth-Token'",
            "Authorization": `Bearer ${(localStorage.getItem('save-autorize') === 'true')? JSON.parse(localStorage.getItem('AthorizationUserData')).token: JSON.parse(sessionStorage.getItem('AthorizationUserData')).token}` 
        },
    }
    );
    return {list: data.data};
  },
);

export const fetchLeaderHomeCheckClient = createAsyncThunk(
  'LeaderHomeClients/fetchLeaderHomeCheckClient',
  async (dataObj) => {
    const { data } = await axiosProxy.get('note/Client/get-client?'+ new URLSearchParams(dataObj),
    {
        headers: {
            "Accept": "application/json",
            'Content-Type': 'application/json',
            "Authorization": `Bearer ${(localStorage.getItem('save-autorize') === 'true')? JSON.parse(localStorage.getItem('AthorizationUserData')).token: JSON.parse(sessionStorage.getItem('AthorizationUserData')).token}` 
        },
    }
    );
    return {list: data};
  },
);

export const fetchLeaderConsolidatedReport = createAsyncThunk(
  'LeaderHomeClients/fetchLeaderConsolidatedReport',
  async (range) => {
    let params = '';
    if(range.length!== 0){
      params = '?'+new URLSearchParams({
        from: new Intl.DateTimeFormat('en-CA', {year: 'numeric', month: '2-digit', day: '2-digit'}).format(getDateFormatFunc(range[0].toLocaleDateString())),
        to: new Intl.DateTimeFormat('en-CA', {year: 'numeric', month: '2-digit', day: '2-digit'}).format(getDateFormatFunc(range[1].toLocaleDateString()))
      })
    } 
    else params = ''
    const { data } = await axiosProxy.get('/note/Client/get-report'+params,
    {
        headers: {
            "Accept": "application/json",
            'Content-Type': 'application/json',
            "Authorization": `Bearer ${(localStorage.getItem('save-autorize') === 'true')? JSON.parse(localStorage.getItem('AthorizationUserData')).token: JSON.parse(sessionStorage.getItem('AthorizationUserData')).token}` 
        },
    }
    );
    return {list: data};
  },
);
