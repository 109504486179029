import { useEffect, useState } from 'react';
import {useDispatch, useSelector} from 'react-redux';

import { selectLeaderHomeClients } from './../../../../redux/home/clients/selectors';
import { fetchLeaderConsolidatedReport } from './../../../../redux/home/clients/asyncActions';


import './index.scss'
import { convertNumber, getDatePeriodFormatFunc, getThisMonthPeriod } from './../../../../services/clientHome';
import MaskedInput from './../../../MaskedInput/MaskedInput';
import CalendarRange from './../../../Calendar/CalendarRange';


const ConsolidatedReport = ({ unDisplay, popupBack }) => {
    const dispatch = useDispatch()
    const [range, setRange] = useState('')
    const [calendActive, setCalendActive] = useState(false)
    const {leaderConsolidatedReportData} = useSelector(selectLeaderHomeClients)

    useEffect(()=>{
        setCalendActive(false)
        setRange(getThisMonthPeriod())
    }, [popupBack])

    useEffect(()=>{
        if(typeof range === 'object' && range.length !== 0){
            dispatch(fetchLeaderConsolidatedReport(range))
        }
        else if(range.indexOf('_') === -1 && range !== ''){
            console.log(getDatePeriodFormatFunc(range));
            
            dispatch(fetchLeaderConsolidatedReport(getDatePeriodFormatFunc(range)))
        }        
    }, [range])


	return (
        <div className={`leader__popup-container deferment-payment ${calendActive? 'calend-active': ''}`} onClick={(e)=>{
            console.log(range);
            if(calendActive && e.target.closest(".leader__popup-consolidated-report-calendar") === null){
                setCalendActive(false)
            }
        }}>
            <div className='d-flex justify-between align-center mb-10'>
                <div className='leader__popup-title calendar'>Сводный отчет</div>
                <div className='popup__close-btn' onClick={unDisplay}/>
            </div>

            <div className='leader__popup-consolidated-report-date-container d-flex align-end'>
                <div className='leader__popup-consolidated-report-date'>
                    <label htmlFor="" className='leader__popup-label text-block'>Период</label>
                    <MaskedInput
                        className={`leader__popup-text-block`}
                        mask="99.99.9999 - 99.99.9999"
                        defaultValue={(range !== null && typeof range === 'object' && range.length !== 0)? `${range[0].toLocaleDateString()} - ${range[1].toLocaleDateString()}`: range}
                        name = 'payment-date'
                        readOnly={false}
                        placeholder=''
                        onChange={(event)=>{
                            setRange(event.target.value)
                        }}
                    />
                    <div className={`leader__popup-consolidated-report-date-button ${(range !== null && typeof range === 'object' && range.length !== 0 || (range.length === 23 && range.indexOf('_') === -1))? '': 'd-none'}`} onClick={()=>{
                        setRange('')                        
                    }}/>
                </div>
                <div className='leader__popup-consolidated-report-button ml-5' onClick={()=>setCalendActive(!calendActive)}/>
                <CalendarRange classes={`leader__popup-consolidated-report-calendar ${calendActive? 'active': ''}`} range={range} setRange={(data)=>{
                    setRange(data) 
                    setCalendActive()
                }}/>
                
            </div>

            <div className='leader__popup-consolidated-report'>
                <div className='leader__popup-consolidated-report-line d-flex justify-between align-center'>
                    <div className="leader__popup-consolidated-report-line-item label">Расходы:</div>
                    <div className="leader__popup-consolidated-report-line-item val">{convertNumber(leaderConsolidatedReportData.expenses)}₽</div>
                </div>
                <div className='leader__popup-consolidated-report-line d-flex justify-between align-center'>
                    <div className="leader__popup-consolidated-report-line-item label">Доход:</div>
                    <div className="leader__popup-consolidated-report-line-item val">{convertNumber(leaderConsolidatedReportData.income)}₽</div>
                </div>
                <div className='leader__popup-consolidated-report-line d-flex justify-between align-center'>
                    <div className="leader__popup-consolidated-report-line-item label">Прибыль:</div>
                    <div className="leader__popup-consolidated-report-line-item val">{convertNumber(leaderConsolidatedReportData.profit)}₽</div>
                </div>
            </div>
        </div>
	)
}
export default ConsolidatedReport;
