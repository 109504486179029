import { createSlice } from '@reduxjs/toolkit';
import { fetchAdminHomeLeadersData } from './asyncActions';

const initialState = {
    adminHomeLeadersData: [],
    adminHomeCheckLeader: {
      id: 0,
      name: '',
      phone: '',   
      login: '',
      password: '',   
      paymentDate: '',
      shutdownDate: '',
    }
};

const adminHomeLeaders = createSlice({
  name: 'AdminHomeLeaders',
  initialState,
  reducers: {
    setAdminHomeLeaders(state, action) {
      state.adminHomeLeadersData = action.payload;      
    },
    setAdminHomeLeadersAdd(state, action) {
      state.adminHomeLeadersData = [...state.adminHomeLeadersData, action.payload];      
    },
    setAdminHomeLeadersEdit(state, action) {
      state.adminHomeLeadersData = state.adminHomeLeadersData.map(item=>(item.id!==action.payload.id)? item: {...item, ...action.payload});      
    },
    setAdminHomeLeadersDelete(state, action) {
      state.adminHomeLeadersData = state.adminHomeLeadersData.filter(item=>item.id!==action.payload);      
    },
    setAdminHomeCheckLeader(state, action) {
      state.adminHomeCheckLeader = action.payload;     
    }
  },
  extraReducers: (builder) => {
    builder.addCase(fetchAdminHomeLeadersData.pending, (state, action) => {
    });

    builder.addCase(fetchAdminHomeLeadersData.fulfilled, (state, action) => {
        state.adminHomeLeadersData = action.payload.list;
    });

    builder.addCase(fetchAdminHomeLeadersData.rejected, (state, action) => {
    });
  },
});

const { actions, reducer } = adminHomeLeaders;


export default reducer;

export const { 
  setAdminHomeLeaders,
  setAdminHomeLeadersAdd,
  setAdminHomeLeadersEdit,
  setAdminHomeLeadersDelete,
  setAdminHomeCheckLeader,
} = actions;

