import React from 'react';
import { createPortal } from 'react-dom';
import {useSelector, useDispatch} from 'react-redux';

import './index-1.0.scss';
import { selectLeaderPopup } from '../../../redux/home/popup/selectors';
import { LeaderPopupBackFunck } from '../../../redux/home/popup/popupSlice';
import AddLeader from './AddLeader/AddLeader';



const PopupContent = ({type, props}) => {
    const dispatch = useDispatch()
    const {popupBack} = useSelector(selectLeaderPopup)

    const ClosePopup = (event) =>{
        if(event.currentTarget === event.target){
            LeaderPopupBackFunck(false, dispatch)
            if(type === '') props.popupTypeEdit('')
        }            
    }

	return (
        <div className={`popup__back ${popupBack}`}>
            <div className="popup__back-wrap d-flex justify-center align-center" onClick={(event)=> ClosePopup(event)}>
                {
                    (type === "AddLeader")? <AddLeader type={type} popupBack={popupBack} unDisplay={()=>LeaderPopupBackFunck(false, dispatch)}/>
                    :(type === "EditLeader")? <AddLeader type={type} popupBack={popupBack} unDisplay={()=>LeaderPopupBackFunck(false, dispatch)}/>
                    : null
                }
            </div>
        </div>
	)
}

const Popup = ({type, props = null}) =>{
    return createPortal(<PopupContent type={type} props={props} />, document.body)
}
export default Popup;