import axios from "axios";
import { logout } from "./redux/store";

// const baseUrl = 'https://localhost:5055'
const baseUrl = 'https://localhost:7194'

const axiosProxy = axios.create({
	//baseURL: baseUrl? baseUrl: '',
    // baseURL: baseUrl ? baseUrl : 'https://localhost:5055',
    baseURL: process.env.REACT_APP_SERVER ? '' : baseUrl
})



// axiosProxy.interceptors.response.use(response => {
// // axios.interceptors.response.use(response => {
//     return response;
// }, error => {
//     console.log(error.response.status)
//     if (error.response.status === 404) {
//         logout()
//         localStorage.setItem('authorize', 'false')
//         localStorage.setItem('AthorizationUserData', JSON.stringify({name: '', token: ''}))
//         localStorage.setItem('token', '')
// 		return Promise.reject(error);
//     } else {
//         throw error;
//     }
// });

try {
    axiosProxy.interceptors.response.use(response => {
    // axios.interceptors.response.use(response => {
        if (response.status > 400) {            
            logout()
            localStorage.setItem('authorize', 'false')
            localStorage.setItem('AthorizationUserData', JSON.stringify({name: '', token: ''}))
            localStorage.setItem('token', '')
        } else {
            return response;
        }        
    }, error => {
        if (error.response.status === 404 || error.response.status === 401) {
            logout()
            localStorage.setItem('authorize', 'false')
            localStorage.setItem('AthorizationUserData', JSON.stringify({name: '', token: ''}))
            localStorage.setItem('token', '')
        }
    });
} catch (error) {
    console.log('Оишибка в запросе аксиоса')
}

export default axiosProxy;
