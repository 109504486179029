// import React from 'react';
import './index.scss';
import { LeaderPopupBackFunck } from './../../../redux/home/popup/popupSlice';
import { useDispatch } from 'react-redux';
import { setUserData } from '../../../redux/authorization/authorization';

const Header = ({setPopupType, query, setQuery}) => {
    const dispatch = useDispatch()
 	return (
		<header className="leader__header">
            <div className='leader__header-container d-flex justify-between align-center'>
                <div className='d-flex align-center mr-30'>
                    <div className='leader__header-button-container'>
                        <div className='leader__header-button-icon calc' onClick={()=>{
                            setPopupType('Calculator')
                            LeaderPopupBackFunck(true, dispatch)
                        }}/>
                    </div>
                    <div className='leader__header-button-container ml-10'>
                        <div className='leader__header-button-icon completed-clients' onClick={()=>{
                            setPopupType('CompletedClients')
                            LeaderPopupBackFunck(true, dispatch)
                        }}/>
                    </div>
                    <div className='leader__header-button-container ml-10'>
                        <div className='leader__header-button-icon consolidated-report' onClick={()=>{
                            setPopupType('ConsolidatedReport')
                            LeaderPopupBackFunck(true, dispatch)
                        }}/>
                    </div>
                    {/* <div className='leader__header-button-container'>
                        <div className='leader__header-button-icon add-user'/>
                    </div> */}
                </div>
                <div className='leader__header-search-container align-center mr-30'>
                    <div className='leader__header-search-icon'/>
                    <input type="text" placeholder='Поиск' className='leader__header-search-input' value={query} onChange={(e)=>{setQuery(e.target.value)}}/>                 
                </div>
                <div className='d-flex align-center'>
                    <div className='leader__header-loguot-container d-flex align-center' onClick={()=>{
                        dispatch(setUserData({id: 0, name: '', token: '', role: ''}))
                    }}>
                        <div className='leader__header-loguot-icon mr-5'/>
                        <div className='leader__header-loguot-title'>Выйти</div>
                    </div>
                </div>
            </div>
        </header>
	)
}
export default Header;